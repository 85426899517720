import React, { Component } from 'react'
import styled from 'styled-components'
import Lightbox from 'react-image-lightbox'
//  UI
import { Container, Flex } from './../../style/global'
import { fontPrimary } from '../../style/variables'
import media from '../../style/media'

// Images Components
import Image1 from './Image1'
import Image2 from './Image2'
import Image3 from './Image3'
import Image4 from './Image4'
import Image5 from './Image5'
import Image6 from './Image6'
import Image7 from './Image7'
import Image8 from './Image8'

// Images
import Image1Pic from './../../images/p1.jpg'
import Image2Pic from './../../images/p2.jpg'
import Image3Pic from './../../images/p3.jpg'
import Image4Pic from './../../images/p4.jpg'
import Image5Pic from './../../images/p5.jpg'
import Image6Pic from './../../images/p6.jpg'
import Image7Pic from './../../images/p7.jpg'
import Image8Pic from './../../images/p8.jpg'

const Heading = styled.h2`
  font-size: 50px;
  letter-spacing: 3px;
  font-family: ${fontPrimary};
  color: #000;
  text-align: center;
  padding: 30px 0;
  font-weight: bolder;
  ${media.phone`
    font-size: 40px;
    margin: 0;
  `};
`

const A = styled.a`
  display: block;
  width: 30%;
  padding: 20px;
  ${media.phone`
    width: 100%;
  `};
`

const Wrapper = styled.div`
  padding: 50px 0 0 0;
  background: #e1e1e1;
`

const images = [
  Image1Pic,
  Image2Pic,
  Image3Pic,
  Image4Pic,
  Image5Pic,
  Image6Pic,
  Image7Pic,
  Image8Pic,
]
const TargetDiv = styled.div`
  height: 85px;
  width: 100%;
`
export default class Gallery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  openLightBox = (e, i) => {
    e.preventDefault()
    console.log('called')
    this.setState(() => ({
      photoIndex: i,
      isOpen: true,
    }))
  }
  closeLightBox = () => {
    this.setState(() => ({
      isOpen: false,
    }))
  }

  render() {
    const { photoIndex, isOpen } = this.state
    return (
      <Wrapper>
        <Container>
          <Heading>Gallery</Heading>
          <Flex wrap justify="center">
            <A onClick={e => this.openLightBox(e, 1)}>
              <Image1 />
            </A>
            <A onClick={e => this.openLightBox(e, 2)}>
              <Image2 />
            </A>
            <A onClick={e => this.openLightBox(e, 3)}>
              <Image3 />
            </A>
            <A onClick={e => this.openLightBox(e, 4)}>
              <Image4 />
            </A>
            <A onClick={e => this.openLightBox(e, 5)}>
              <Image5 />
            </A>
            <A onClick={e => this.openLightBox(e, 6)}>
              <Image6 />
            </A>
            <A onClick={e => this.openLightBox(e, 7)}>
              <Image7 />
            </A>
            <A onClick={e => this.openLightBox(e, 8)}>
              <Image8 />
            </A>

            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length]
                }
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (photoIndex + images.length - 1) % images.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % images.length,
                  })
                }
              />
            )}
          </Flex>
        </Container>
        <TargetDiv id="appointment-form" />
      </Wrapper>
    )
  }
}
