import RachelleImage from './Images/RachelleImage'
import BijishImage from './Images/Bijish'
import BrindaImage from './Images/Brinda'
import ShimpyImage from './Images/Shimpy'
import LanaImage from './Images/Lana'
import JanaImage from './Images/Jana'
import GitikaImage from './Images/Gitika'
import DuyenImage from './Images/Duyen'
import LimImage from './Images/Lim'
import ChunImage from './Images/Chun'
import Tsion from './Images/Tsion'
import User from './Images/Dummy'
import User1 from './Images/User1'
import Mohit from './Images/Mohit'
import HimaniImage from './Images/Himanii'
import KamSumYe from './Images/KamSumYue'
import Siju from './Images/Siju'

export default [
  {
    name: 'BIJISH',
    qualification: 'BPT, MPT',
    occupation: 'PHYSIOTHERAPIST',
    description:
      'Bijish completed his Bachelor of physiotherapy in 2004 and obtained his master of physiotherapy degree in 2006. During his 10+ years of experience, he had the privilege of treating patients of all ages and backgrounds in various multidisciplinary settings. Bijish has experience working with a wide variety of musculoskeletal and neurological injuries. His assessments focus on targeting the root cause of injury in order to rehabilitate and prevent future injuries. He uses a hands on manual therapy based approach with appropriate exercise prescription and electrotherapy to aid treatment. Bijish also has a background in sports physiotherapy.He worked as a team physiotherapist for the Ajman (UAE) volleyball team. Additionally Bijish obtained his certification in Functional Dry Needling and Kinesio Taping.',
    image: BijishImage,
  },
  {
    name: 'SHIMPY',
    qualification: 'BPT, MPT',
    occupation: 'PHYSIOTHERAPIST',
    description:
      "Shimpy completed her Bachelor of Physiotherapy in 2006 and obtained her Masters of Sports Physiotherapy in 2008. She always had a passion for Physical Therapy, and a desire to help others and this is why she choose to be a Physiotherapist. During her 5 years of experience, She has dedicated herself in assisting her client's return to work, return to sports and helping clients to achieve their goals while returning to preinjury level. Shimpy's strength is in her ability to listen to her clients patiently and focus on targeting the root cause of injury in order to rehabilitate and prevent future injuries.",
    more: [
      "She believes that many factors play's important role in maintaining the health of her patients which is why a broad approach using multiple forms of treatment is important. Treatments usually involve various manual therapy techniques, exercise programs, instruction and ergonomic advice in pain management techniques, electrotherapeutic modalities, Home programme.",
      'Shimpy has also worked as a Sports Physiotherapist in PAP (Punjab Armed Police) in India. She has an international exposure of working in Common Wealth Games 2010 as an Assistant Project Officer. Along with that she has worked as a Physiotherapist in Clinical settings where she has experience treating all kind of patients specially Musculoskeletal and Neurological injuries etc. Outside of the Clinic, she maintains an active lifestyle and has a strong interest in cycling, dancing and badminton.',
    ],
    image: ShimpyImage,
  },
  {
    name: 'GITIKA BINDRA',
    qualification: 'RMT',
    occupation: 'Massage Therapist',
    description: `Since the clinic's opening, Gitika Bindra has been an outstanding member of our team and a renowned massage therapist with her growing clientele.  With nearly 2400 hours of massage therapy training, she has helped to alleviate tension and restrictions within muscles, tendon, ligaments, fascia, and nerves.  She also spends her time as a massage clinical instructor teaching students proper massage techniques and skills.
      Gitika is proud to be a certified RAPID NeuroFascial Reset provider: a new soft tissue, movement-based therapeutic technique that addresses central nervous system's role reducing tension and discomfort.  She also has certifications in hot stone and cupping massages.
      On top of that, she provides a wide range of massage types including deep tissue, therapeutic,  myofascial release, and sports massage to resolve neuromuscular conditions.
      If you are interested in a more relaxing massage, Gitika is also a provider of relaxation, post and prenatal, as well as Swedish massages.
      Gitika aims to use her techniques to loosen muscles and connective tissues to reduce dysfunction, promote well-being, and improve range of motion, as well as formulate a treatment plan according to each client's needs.  Book with Gitika today!`,
    image: GitikaImage,
  },
  {
    name: 'Dr. Samuel Lim ',
    occupation: 'Chiropractor',
    description:
      'Born and raised in Calgary, Samuel’s aptitude for anatomy, background in sports and interest in the health and well-being of others motivated him to pursue chiropractic studies in 2007 after obtaining his B.Sc. degree from the University of Calgary. He attended Oregon’s University of Western States, a prestigious school reputed for its unsurpassed, integrative and natural approach to wellness and for its rigorous training, well-grounded in the most up-to-date evidence-informed principles and guidelines. He graduated cum laude, simultaneously earning a second B.Sc. degree in Human Biology.',
    more: [
      'Dr. Lim is fully committed to providing quality patient-centered care. He is very thorough in his treatment approach and strives to achieve optimal results in all his patients. He utilizes a variety of techniques including manual/diversified joint manipulation, low-force high-impulse technique with the Activator and Impulse instruments (offering a more gentle and effective method for patients with osteoporosis or suffering from acute pain), Graston, Trigenics, kinesiotaping and much more.',
      'Samuel spends his free time with his wife and young children. Music, language, volleyball and ultimate frisbee are some of his interests.',
    ],
    image: LimImage,
  },
  {
    name: 'Brinda Rao',
    qualification: 'M.SC, RD, CDE',
    occupation: 'Dietitian',
    description:
      'Brinda Rao is a registered Dietitian with over 15 years experience in the field of Nutrition. She provides evidence based Nutritional counselling services & support for patients of multicultural and diverse background. Her field of expertise includes preventative health, Chronic disease, pre-natal nutrition & weight management education. Brinda is also a Certified Diabetes Educator & provides counselling to people with Diabetes as well as develops Diabetes prevention plans. She works with adults in the management of thee Diabetes to help them achieve optimal blood glucose control.',
    image: BrindaImage,
  },
  {
    name: 'Dr. Chun Lan Song',
    qualification: 'TCMD, R.Ac',
    occupation: 'Acupuncture',
    description:
      'As a Doctor of Traditional Chinese Medicine and Registered Acupuncturist in Alberta, Dr. Song brings her extensive knowledge to us here at SEWA. In all of her treatments she begins with some Acupressure with her hands while she is assessing your problems, and then will gently move into other therapies such as Cupping and Acupuncture, she has been treated many patients who had suffered from Frozen Shoulder, Sciatic syndrome, Migraine, Anxiety, and Insomnia. Her goal is to help patients getting better and better. Her treatments are so relaxing you won’t want to leave!',
    more: [
      'In her words:',
      '  Chinese Medicine and Acupuncture have been used in China for thousands of years, and it works for all kinds of illnesses and disorders, it is reliable and trusted. let it begin to work for you here in the west world. After my treatments you will not be scared by needles anymore, you will feel relaxed and comfortable and gradually your whole body will begin to change and come into balance. ​',
    ],
    image: ChunImage,
  },
  {
    name: 'Tsion Tsega',
    qualification: 'RMT',
    occupation: 'Massage Therapis ',
    description: `Tsion grew up in Addis Ababa, Ethiopia and Loves her life in Canada. She love to help her clients feel better with her knowledge and experienced Massage Techniques. Your massage with Tsion will be tailored to your problematic areas and she help her clients to decrease muscle, joint pain, stress, anxiety, muscle tension and increase mobility, promote relaxation and improve quality of sleep.
`,
    more: [
      'Tsion has been therapist for 3 years and certified for dynamic cupping, Hot stones, Relaxation, Deep Tissue, Therapeutic, Myofascial release, Stretching, Prenatal and Post Natal, Aroma Therapy and Advanced Orthopedic Massage. Please see Tsion to feel better with amazing Massage tecnique ​',
    ],
    image: Tsion,
  },
  {
    name: 'Khushali Raval',
    occupation: 'PHYSIOTHERAPIST',
    image: User,
  },
  { name: 'Kam Sum Yue', 
  occupation: 'Massage Therapist', image: KamSumYe, description: ' Kam Sum is a registered massage therapist and a current student of Chinese Medicine and Acupuncture.  He uses massage as a remedy for dysfunctions identified through orthopaedic assessment.  With individualized treatments, he provides effective pain relief and improved mobility.' },

  {
    name: 'Himani goel',
    occupation: 'PHYSIOTHERAPIST',
    qualification: '',
    description: `Himani’s first hand experience with Physiotherapy started at the age of 15, when her grandmother’s spinal injury at the age of 55 made her bed ridden. Physiotherapy created a special place in her heart when the physiotherapist made her  grandma mobile enough to be able to sit and feed herself and adapt to a new way of living against all hopes.
    She completed her Bachelor’s in Physiotherapy in 2005 from Amarjyoti Institute of Physiotherapy, Delhi University She pursued her Masters in Physiotherapy from 2006-2008 from Maharishi Dayanand University along with her successful practice.
    She has worked in various Physiotherapy settings in Calgary since 2010.
     She is a trained Mckenzie therapist, has done Cyriax courses empowering her strong diagnostic and treatment skills. She is a certified Pelvic Floor therapist . She is also certified in anatomical acupuncture from AFCI. `,
    more: [
      `She holds expertise in the treatment of musculoskeletal injuries, chronic pain, women’s health along with spinal injuries, neurological conditions, geriatrics and cardiorespiratory physiotherapy.
      Her treatment approach involves a thorough and comprehensive biopsychosocial assessment followed by highly customised hands on treatments and education about maintenance of pain free state/condition. She focuses on prevention of injuries and enhancing body’s capacity to heal and rejuvenate while treating the existing injuries/ conditions. `,
    ],
    image: HimaniImage,
  },

  {
    name: 'Siju John',
    occupation: 'PHYSIOTHERAPIST',
    qualification: '',
    description: `Siju John graduated from Mangalore University, India in 1999 and has been practicing Physiotherapy for almost 18 years. His experience varies between acute care, private practice, amputee rehabilitation, geriatric care, and pediatric rehab. Siju John has worked in multispecialty hospitals in India and in Canada. Siju John has been clinical supervisor in different rehabilitation departments. `,
    more: [
      `Siju John has a keen interest in vestibular rehabilitation. He obtained his Introductory and advanced vestibular rehabilitation certificate a few years ago. His other advanced skills include thorough assessment skills, myofascial release technique, client centered goal oriented exercise prescription and home exercise programs .`,
      `He uses holistic approach to help people with pain and dysfunction and focuses on regaining mobility to participate in daily activities of life and recreational activities. Eighteen years of work as a physiotherapist  and his intense attention to research and best practice methods allows him to provide treatment for many complex Motor vehicle accidents, work related injuries, post-surgical conditions, and neuro rehabilitation ( Stroke, MS, Parkinson’s etc. )Siju will be completing his Level -1 functional dry needling by mid-March 2020.`,
    ],
    image: Siju,
  },
]
