import React, { Component } from 'react'
import styled from 'styled-components'
import TestimonialsData from './TestimonialsData'
import quote from './../../images/left-quote-sketch.png'

// UI
import { Container, Flex } from './../../style/global'
import { colorPrimary, fontPrimary } from '../../style/variables'
import media from './../../style/media'

const Background = styled.div`
  background: ${colorPrimary};
  padding: 50px 0px 0 0;
`
const Heading = styled.h2`
  font-size: 50px;
  letter-spacing: 3px;
  font-family: ${fontPrimary};
  color: #fff;
  text-align: center;
  padding: 30px 0;
  font-weight: bolder;
  ${media.phone`
    font-size: 40px;
    margin: 0;
  `};
`

const TestimonialCard = styled.div`
  background: #fff;
  border-radius: 5px;
  width: 30%;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);
  position: relative;
  margin-bottom: 20px;
  > span {
    display: block;
    text-align: center;
    padding: 30px 0 20px 0;
    > img {
      height: 50px;
      width: 50px;
      margin: 0;
    }
  }

  > p {
    padding: 0px 40px 50px 40px;
    font-family: ${fontPrimary};
    text-align: justify;
    font-size: 18px;
  }

  > h4 {
    font-size: 25px;
    color: rgba(0, 0, 0, 0.6);
    font-family: ${fontPrimary};
    text-align: center;
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  ${media.phone`
    width: 100%;
    margin-bottom: 30px;
  `};
`

const TargetDiv = styled.div`
  height: 85px;
  width: 100%;
`

export default class Testimonials extends Component {
  state = {
    data: TestimonialsData,
  }
  render() {
    return (
      <Background>
        <Container>
          <Heading>Testimonials</Heading>
          <Flex wrap justify="space-between">
            {this.state.data.map(({ name, description }) => (
              <TestimonialCard key={name}>
                <span>
                  <img src={quote} alt="" />
                </span>
                <p>{description}</p>
                <h4>{name}</h4>
              </TestimonialCard>
            ))}
          </Flex>
        </Container>
        <TargetDiv id="gallery" />
      </Background>
    )
  }
}
