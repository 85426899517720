export default [
  {
    name: 'Shaikh Arif',
    description:
      'I honestly have been to too many places for my injuries  treatments in past, but i i have  never visited a place like sewa, first thing first thier customer service is outstanding , secondly staff is super friendly and the main thing is service provider is highly knowledgeable dr. Shimbi and her husband both took cared of me as though i was thier family member. So happy to be there. Thank you sewa physiotherapy',
  },
  {
    name: 'Pankaj Panghaal',
    description:
      'Superb Treatment, Vary Experienced and co-operative staff, Soft spoken, Easy to access, They gave me more time then my appointment (just for better treatment), they go out of the box to help you...From my experience ....no complains what so ever.',
  },
  {
    name: 'Anil Verma',
    description:
      'Thanks a lot to Shimpy for providing excellent service. She has great experience and very good understanding in her field. All staff members are very cooperative. I would highly recommend this facility for great service of great staff working in.',
  },
  {
    name: 'Jagroop Singh Pandher',
    description:
      'I got ankle physiotherapy treatment from Miss Shimpy and Mr. Gopal in the last 3 months. She listened my problem in detail and then started the exercises and explained everything in an easy understandable way. The clinic is clean, silent and peaceful.  They always started my treatment with 2-3 mins on showup without any delay. Recommended!',
  },
  {
    name: 'Harpreet Brar',
    description:
      'I’m very happy with my wonderful experience at Sewa Physiotherapy and Message Clinic. I would like to specially thanks to Shimpy and Gopal, they are very engaged with my care and explained me very well about my knee injury and I’ve seen marked improvement over time by following their advice. They are very professional and friendly. I would highly recommend this place to anyone who is looking for a good experience.',
  },
  {
    name: 'Pamela Araujo',
    description:
      'Moved to the same area and decided to try, had my first appointment with Joanie (Accumpunture), I really liked her, she is very knowledgeable...the clinic is very new, modern and clean. Will be back for few more sessions. I definitely recommend this place and will be trying their massage very soon as well.',
  },
]
