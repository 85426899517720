import React, { Component } from 'react'
import styled from 'styled-components'
import ScrollTo from 'react-scroll-into-view'
import Slide from 'react-reveal/Slide'
import Bg from './../../images/background-stethoscope.jpg'
//  Components
//  UI
import { Container, Flex } from './../../style/global'
import { fontPrimary, colorPrimary } from '../../style/variables'
import media from '../../style/media'

const StyledScrollTo = styled(ScrollTo)`
  ${media.phone`
    text-align: center;
    padding: 10px 0px;
  `};
`

const Heading = styled.h1`
  color: #fff;
  font-family: ${fontPrimary};
  font-size: 60px;
  text-align: center;
  line-height: 1.5;
  padding: 150px 0 0 0;
  margin: 0;
  > span {
    font-size: 80px;
    color: ${colorPrimary};
    letter-spacing: 2px;
  }
  ${media.phone`
    font-size: 40px;
    padding: 100px 0 0 0;
    > span {
      font-size: 50px;
    }
  `};
`

const Background = styled.div`
  height: calc(100vh - 162px);
  width: 100%;
  background: url(${Bg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`
const BackgroundDimmer = styled.div`
  height: calc(100vh - 162px);
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
`

const Button = styled.button`
  background: ${({ primary }) => (primary ? colorPrimary : 'none')};
  border-radius: 30px;
  border: solid 1px ${colorPrimary};
  color: ${({ primary }) => (primary ? '#fff' : colorPrimary)};
  font-family: ${fontPrimary};
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.21);
  margin-left: ${({ primary }) => (primary ? '0' : '30px')};
  &:hover {
    transform: scale(1.1);
    color: #fff;
    background: ${colorPrimary};
  }
  ${media.phone`
    margin: 0 0 20px 0;
  `};
`

const TargetDiv = styled.div`
  height: 85px;
  width: 100%;
`

export default class Landing extends Component {
  render() {
    return (
      <Background id="landing">
        <BackgroundDimmer>
          <Container>
            <Heading>
              Making your life
              <br />
              <span>EASIER & HAPPY</span>
            </Heading>
            <Slide left>
              <Flex justify="center">
                <StyledScrollTo alignToTop selector="#appointment-form" smooth>
                  <Button primary>Request Appointment</Button>
                </StyledScrollTo>
                <StyledScrollTo alignToTop selector="#about-us">
                  <Button>Learn More</Button>
                </StyledScrollTo>
              </Flex>
            </Slide>
          </Container>
          <TargetDiv id="about-us" />
        </BackgroundDimmer>
      </Background>
    )
  }
}
