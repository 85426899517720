import React, { Component } from 'react'
import styled from 'styled-components'
import TeamData from './TeamData'
import ScrollTo from 'react-scroll-into-view'

//  UI
import { Container, Flex } from './../../style/global'
import { fontPrimary, colorPrimary } from '../../style/variables'
import media from '../../style/media'

const Heading = styled.h2`
  font-size: 50px;
  letter-spacing: 3px;
  font-family: ${fontPrimary};
  text-align: center;
  padding: 60px 0;
  font-weight: 900;
  ${media.phone`
    padding:60px 0;
    margin: 0;
  `};
`

const MemberData = styled.div`
  flex: 1;
  > h3 {
    font-size: 50px;
    margin: 0;
    font-family: ${fontPrimary};
    font-weight: 900;
    text-transform: uppercase;
  }
  > h4 {
    font-size: 40px;
    margin: 0;
    font-family: ${fontPrimary};
    font-weight: 700;
    color: rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
  }
  > span {
    font-family: ${fontPrimary};
    color: ${colorPrimary};
    display: block;
    margin: 10px 0 20px 0px;
    font-weight: 700;
  }
  > p {
    text-align: justify;
    margin-bottom: 10px;
  }
  ${media.phone`
    > h3 {
      font-size: 30px;
      mar
    }
    > h4 {
      font-size: 30px;
    }
  `};
`

const ReadMore = styled.a`
  color: ${colorPrimary};
  text-transform: italic;
  cursor: pointer;
`

const MemberImage = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  ${media.phone`
    order: -1;
    margin-bottom: 30px;
  `} /* align-items: center; */;
`

const Thumbnails = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1100px;
  margin: 50px auto 0px auto;
  ${media.phone`
    justify-content: space-evenly;
  `};
`

const ThumbnailWrap = styled.div`
  > div {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    margin: 30px 50px;
    opacity: ${({ highlight }) => (highlight ? 1 : 0.85)};
    box-shadow: ${({ highlight }) =>
      highlight
        ? '0 2px 4px rgba(0, 0, 0, 0.2)'
        : '0 5px 7px rgba(0, 0, 0, 0.4)'};
    ${({ highlight }) =>
      highlight
        ? 'transform: translateY(-3px) scale(1.3);'
        : ''} transition: all 0.2s ease-out;
    &:hover {
      cursor: pointer;
      opacity: 1;
      box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);
      transform: translateY(-3px) scale(1.3);
    }
  }
  .name-tn,
  &.name-tn {
    font-size: 14px;
    color: var(--colorPrimary);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 30px;
    text-decoration: underline;
  }
  ${media.phone`
    > div {
    height: 70px;
    width: 70px;
    margin: 20px 10px;
    }
  `};
`
const TargetDiv = styled.div`
  height: 85px;
  width: 100%;
`

export default class Team extends Component {
  state = {
    data: TeamData,
    selected: TeamData[0],
    readMore: false,
  }

  select = ({ name }) => {
    this.setState(({ data, readMore }) => {
      return {
        selected: data.find(
          ({ name: TeamMemberName }) => TeamMemberName === name
        ),
        readMore: false,
      }
    })
  }

  readMoreToggle = e => {
    e.preventDefault()
    this.setState(({ readMore }) => ({
      readMore: !readMore,
    }))
  }

  render() {
    const {
      name,
      description,
      qualification,
      image: Image,
      occupation,
      more,
    } = this.state.selected
    const { data: team } = this.state
    return (
      <Container>
        <Heading>Team</Heading>
        <Flex id={name}>
          <Flex flex="1" column>
            <MemberData>
              <h3>{name}</h3>
              <h4>{occupation}</h4>
              <span>{qualification}</span>
              <p>{description}</p>
              {more &&
                this.state.readMore &&
                more.map(para => <p key={para.slice(1, 20)}>{para}</p>)}
              {more &&
                !this.state.readMore && (
                  <ReadMore onClick={this.readMoreToggle}>
                    <em>Read more</em>
                  </ReadMore>
                )}
              {more &&
                this.state.readMore && (
                  <ScrollTo alignToTop selector="#team">
                    <ReadMore onClick={this.readMoreToggle}>
                      <em>Read Less</em>
                    </ReadMore>
                  </ScrollTo>
                )}
            </MemberData>
          </Flex>
          {Image && (
            <MemberImage>
              <Image />
            </MemberImage>
          )}
        </Flex>
        <Thumbnails>
          {team.map(({ name: MemberName, image: Thumbnail }) => (
            <ScrollTo
              className={!Thumbnail && 'name-tn'}
              key={MemberName}
              alignToTop
              selector="#team"
            >
              <ThumbnailWrap
                onClick={() => this.select({ name: MemberName })}
                highlight={MemberName === name}
                className={!Thumbnail && 'name-tn'}
              >
                {Thumbnail ? (
                  <Thumbnail />
                ) : (
                  <p className="name-tn">{MemberName}</p>
                )}
              </ThumbnailWrap>
            </ScrollTo>
          ))}
        </Thumbnails>
        <TargetDiv id="testimonials" />
      </Container>
    )
  }
}
