import React from 'react'
import Layout from '../components/layout'
import LandingScreen from './../components/Landing/Landing'
import AboutScreen from './../components/AboutUs/about'
import DepartmentsScreen from './../components/Departments/Departments'
import TeamScreen from './../components/Team/Team'
import TestimonialsScreen from './../components/Testimonials/Testimonials'
import GalleryScreen from './../components/Gallery/Gallery'
import AppointmentScreen from './../components/AppointmentForm/Appointment'
import ContactUs from './../components/ContactUs/ContactUs'

const IndexPage = () => (
  <Layout>
    <LandingScreen />
    <AboutScreen />
    <DepartmentsScreen />
    <TeamScreen />
    <TestimonialsScreen />
    <GalleryScreen />
    <AppointmentScreen />
    <ContactUs />
  </Layout>
)

export default IndexPage
