import React from 'react'
import { withFormik, Field } from 'formik'
import { StyledForm as Form } from './../../style/global'

const date = new Date()

const UUIDForm = ({ values, isSubmitting, handleChange, handleBlur }) => (
  <Form>
    <label htmlFor="name">Name</label>
    <Field
      type="text"
      id="name"
      name="name"
      placeholder="Enter your name here..."
      required
    />
    <label htmlFor="email">Email</label>
    <Field
      type="email"
      id="email"
      name="email"
      placeholder="Enter your email here..."
      required
    />
    <label htmlFor="phoneNo">Phone Number</label>
    <Field
      type="tel"
      id="phoneNo"
      name="phoneNo"
      placeholder="Enter your phone number here..."
      required
    />
    <label htmlFor="department">Department</label>
    <select
      id="department"
      name="department"
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.department}
      required
    >
      <option value="physiotherapy">Physiotherapy</option>
      <option value="chiropractic">Chiropractic</option>
      <option value="acupuncture">Acupuncture</option>
      <option value="dietitian">Dietitian</option>
      {/* <option value="massage">Massage</option> */}
      {/* <option value="yoga">Yoga</option> */}
    </select>
    <label htmlFor="gender">
      Gender
      <label htmlFor="male">Male</label>
      <Field type="radio" id="male" name="gender" value="male" required />
      <label htmlFor="female">Female</label>
      <Field type="radio" id="Female" name="gender" value="female" required />
    </label>
    <label htmlFor="date">Date</label>
    <Field
      type="date"
      id="date"
      name="date"
      min={`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`}
      required
    />
    <label htmlFor="time">Time</label>
    <select
      id="time"
      name="time"
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.time}
      required
    >
      <option value="9 - 10">9 - 10</option>
      <option value="10 - 11">10 - 11</option>
      <option value="11 - 12">11 - 12</option>
      <option value="12 - 13">12 - 13</option>
      <option value="13 - 14">13 - 14</option>
      <option value="14 - 15">14 - 15</option>
      <option value="15 - 16">15 - 16</option>
      <option value="16 - 17">16 - 17</option>
      <option value="17 - 18">17 - 18</option>
      <option value="18 - 19">18 - 19</option>
      <option value="19 - 20">19 - 20</option>
    </select>
    <button disabled={!!isSubmitting} type="submit">
      Request Appointment
    </button>
  </Form>
)

const FormikUUID = withFormik({
  mapPropsToValues() {
    return {
      name: '',
      email: '',
      phoneNo: '',
      department: '',
      gender: '',
      date: '',
      time: '',
    }
  },
  handleSubmit(personDetails, { props, setErrors, setSubmitting }) {
    console.log(personDetails)
    setSubmitting(true)
    props
      .onSubmit(personDetails)
      .then(res => {
        setSubmitting(false)
      })
      .catch(err => {
        setErrors({ error: `Something Went wrong ${err}` })
        setSubmitting(false)
      })
  },
})(UUIDForm)

export default FormikUUID
