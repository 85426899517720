import PhysiotherapyImage from './PhysiotherapyImage'
import ChiropracticImage from './ChiropraticImage'
import DieticianImage from './DieticianImage'
import AcupunctureImage from './AcupunctionImage'
import MassageImage from './MassageImage'
import YogaImage from './YogaImage'

export default [
  {
    name: 'Physiotherapy',
    description: [
      'Physiotherapy is a health care profession which assists people to restore, maintain and maximize their strength, function, movement, and overall well-being. Physiotherapy can be use as a part of treatment or to prevent injuries as well.',
    ],
    points: [
      {
        title: 'PHYSIOTHERAPY BENEFITS',
        points: [
          'Managing acute and chronic conditions, activity limitations, and participation restrictions',
          'Improving and maintaining optimal functional independence and physical performance',
          'Rehabilitating injury and the effects of disease or disability with therapeutic exercise programs and other interventions',
          'Educating and planning maintenance and support programs to prevent re-occurrence, re-injury or functional decline',
          'Promoting optimal mobility, physical activity and overall health and wellness',
          'Preventing disease, injury, and disability',
          'Physiotherapy benefits include decreasing pain, improving joint mobility, increasing strength, coordination, improved cardio-respiratory function',
          'Everyone can be benefit from physiotherapy whether you are living with a chronic illness, recovering from a work injury or suffering after that weekend hockey game.',
        ],
      },
    ],
    Image: PhysiotherapyImage,
  },
  {
    name: 'Chiropractor',
    description: [
      'Chiropractic is a non-invasive, hands-on health care discipline that focuses on the musculoskeletal system. Chiropractors practice a manual approach, providing diagnosis, treatment and preventive care for disorders related to the spine, pelvis, nervous system and joints.',
    ],
    points: [
      {
        title: 'There are many reasons to seek chiropractic care: ',
        points: [
          'Back pain',
          ' Neck pain',
          'Headache',
          'Whiplash',
          'Strains and sprains',
          'Work and sports injuries',
          'Arthritis',
          'Limited range of motion in the back, shoulder, neck or limbs ',
          'General health and well-being',
        ],
      },
    ],
    Image: ChiropracticImage,
  },
  {
    name: 'Acupuncture',
    description: [
      'Acupuncture is the treatment modality of Traditional Chinese Medicine (TCM) most widely used, recognized and taught in the Western world. It presupposes the existence of a vital energy, or "Qi" (pronounced "chi"), that is essential to life and that flows throughout the body. This energy is transported by a system of channels called meridians. These meridians represent a large network of superficial and deep pathways that enable a connection between the inner and outer aspects of our bodies.',
      'Acupuncture points are found on the surface of your body and at very specific locations along themeridians. Mapped and documented for thousands of years by Asian societies (Chinese, Japanese, Korean, Vietnamese, Taiwanese, etc.), these points have their own characteristics and indications.',
      'The selection and method of stimulation of these points are determined by the acupuncturist and based on Traditional Chinese Medicine energy and pattern diagnosis. By selecting a combination of points, the treatment will have an effect on one or more bodily organs, areas or functions, and therefore act on a set of varied symptoms related to the initial cause of the imbalance.',
      'Acupuncture is a unique therapeutic method that enables us to influence and treat the interior by using a synergy of points on the exterior aspect of our bodies. ',
    ],
    Image: AcupunctureImage,
  },
  {
    name: 'Dietitian',
    description: [
      'A dietitian is a degree-qualified health professional who helps to promote nutritional well-being, treat disease and prevent nutrition related problems and provides practical, safe advice, based on current scientific evidence.',
      'Dietitians are the only nutrition professionals to be regulated by law and governed by an ethical code, to ensure that they always work to the highest standard.',
    ],
    points: [
      {
        title: 'What dietitians do?',
        points: [
          ' Translate nutrition science into understandable, practical information about food, allowing people to make. ',
          ' Appropriate lifestyle and food choices treat a range of medical conditions with dietary therapy, specially tailored to each individual. ',
          ' Advise on healthy eating for all ages, races, cultures and social groups. ',
          'Conduct research relating to health, diet and nutrition. ',
          ' Write and provide comment for the media. ',
          ' Give talks and lectures to health professionals and the public. ',
          ' Advise industry and government. ',
          ' Give talks and lectures to health professionals and the public. ',
          ' Teach in higher education. ',
        ],
      },
      {
        title:
          'Who can see a dietitian? You can see a dietcian if you have been diagnosed with a medical condition or have concerns with any of the areas below:',
        points: [
          'Diabetes  ',
          'Food allergy and intolerance ',
          'Gastroenterology (digestive system) ',
          'HIV/AIDS ',
          'Mental Health ',
          'Obesity',
          'Oncology (cancer) ',
          'Renal (kidneys) ',
        ],
      },
    ],
    Image: DieticianImage,
  },
  {
    name: 'Massage',
    description: [
      'Massage therapy consists primarily of hands-on manipulation of the soft tissues of the body, specifically, the muscles, connective tissue, tendons, ligaments and joints for the purpose of optimizing health.',
      'Chiropractors use a combination of treatments, all of which are predicated on the specific needs of the individual patient. After taking a complete history and diagnosing a patient, a chiropractor can develop and carry out a comprehensive treatment/management plan, recommend therapeutic exercise and other non-invasive therapies, and provide nutritional, dietary and lifestyle counselling.',
      'For many conditions, such as lower back pain, chiropractic care is frequently the primary method of treatment. Where other conditions exist, chiropractic care may complement or support medical treatment by relieving the musculoskeletal aspects associated with the condition.',
      'Chiropractic care may also be used to provide symptomatic relief for patients with chronic conditions. According to patient surveys, by treating the musculoskeletal elements of such disorders, chiropractic treatment has been shown to improve the general well-being of the patient. ',
    ],
    points: [
      {
        title: 'There are the types of Massage we provide:',
        points: [
          'Swedish massage ',
          'Deep tissue massage',
          'Sports massage ',
          'Trigger point massage ',
          'Sports massage',
          'Massage for pregnant Women',
          'Relaxation massage',
          'Head massage',
          'Therapeutic massage ',
          'Stone massage',
          'Stretching',
          'Myofascial Release',
        ],
      },
      {
        title: 'MASSAGE BENEFITS, RELEASING:',
        points: [
          'Anxiety  ',
          'Digestive disorders',
          'Headaches',
          'Insomnia related to stress',
          'Myofascial pain syndrome',
          'Soft tissue strains or injuries',
          'Sports injuries ',
          'Temporomandibular joint pain',
        ],
      },
    ],
    Image: MassageImage,
  },
  // {
  //   name: 'Yoga Meditation',
  //   description: [
  //     'Acupuncture is the treatment modality of Traditional Chinese Medicine (TCM) most widely used, recognized and taught in the Western world. It presupposes the existence of a vital energy, or "Qi" (pronounced "chi"), that is essential to life and that flows throughout the body. This energy is transported by a system of channels called meridians. These meridians represent a large network of superficial and deep pathways that enable a connection between the inner and outer aspects of our bodies.',
  //     'Acupuncture points are found on the surface of your body and at very specific locations along themeridians. Mapped and documented for thousands of years by Asian societies (Chinese, Japanese, Korean, Vietnamese, Taiwanese, etc.), these points have their own characteristics and indications.',
  //     'The selection and method of stimulation of these points are determined by the acupuncturist and based on Traditional Chinese Medicine energy and pattern diagnosis. By selecting a combination of points, the treatment will have an effect on one or more bodily organs, areas or functions, and therefore act on a set of varied symptoms related to the initial cause of the imbalance.',
  //     'Acupuncture is a unique therapeutic method that enables us to influence and treat the interior by using a synergy of points on the exterior aspect of our bodies. ',
  //   ],
  //   Image: YogaImage,
  // },
]
