import Img from 'gatsby-image'
import styled from 'styled-components'

import media from './../../style/media'

export default styled(Img)`
  height: 300px;
  width: 480px;
  border-radius: 3px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.21);
  ${media.phone`
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
  `};
`
