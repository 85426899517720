import React, { Component } from 'react'
import styled from 'styled-components'
import AppointmentForm from './AppointmentForm'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
//  UI
import { Container } from './../../style/global'
import { fontPrimary } from '../../style/variables'
import media from '../../style/media'

const Heading = styled.h2`
  font-size: 50px;
  letter-spacing: 3px;
  font-family: ${fontPrimary};
  text-align: center;
  padding: 60px 0;
  font-weight: 900;
  ${media.phone`
    font-size: 40px;
    padding:60px 0;
    margin: 0;
  `};
`

const TargetDiv = styled.div`
  height: 85px;
  width: 100%;
`

const StatusDiv = styled.div`
  position: fixed;
  width: 100%;
  margin-top: 200px;
  z-index: 25;
  display: flex;
  justify-content: center;
  > div {
    border-radius: 20px;
    padding: 10px 20px;
  }
`

const lambdaURL =
  process.env === 'production' ? '/.netlify/functions' : '/localhost:9000'

const Status = ({ waiting, success, fail }) => (
  <StatusDiv>
    <div>
      {waiting && 'Waiting'}
      {success &&
        'Your appointment has been noted. We will revert back to you confirming your appointment.'}
      {fail &&
        'Your appointment cannot be booked right now. Please try again or check your connection.'}
    </div>
  </StatusDiv>
)
export default class Appointment extends Component {
  state = {
    waiting: false,
    success: false,
    failed: false,
  }

  submitForAppointment = personDetails => {
    this.setState(() => ({
      waiting: true,
    }))
    console.log('Doing request.')

    return axios
      .post(`/.netlify/functions/request_demo/request_demo.js`, personDetails)
      .then(response => {
        console.log('Done.')
        toast.success('Your request has been received , we will get back soon')
        this.setState(() => ({
          waiting: false,
          success: true,
        }))
        setTimeout(() => {
          this.setState(() => ({
            waiting: false,
            success: false,
          }))
        }, 3000)
        return Promise.resolve(
          'Your request has been received , we will get back soon'
        )
      })
      .catch(error => {
        this.setState(() => ({
          waiting: false,
          fail: true,
        }))
        toast.error('⤫ Shoot! Something went wrong.')
        setTimeout(() => {
          this.setState(() => ({
            waiting: false,
            fail: false,
          }))
        }, 3000)
        return Promise.reject(error)
      })
  }

  render() {
    const { waiting, success, fail } = this.state
    return (
      <Container>
        {(waiting || success || fail) && <Status {...this.state} />}
        <Heading>Request An Appointment</Heading>
        <AppointmentForm onSubmit={this.submitForAppointment} />
        <TargetDiv id="contact-us" />
      </Container>
    )
  }
}
