import React, { Component } from 'react'
import styled from 'styled-components'

//  UI
import { Container, Flex as FlexShort } from './../../style/global'
import { fontPrimary, fontSecondary } from '../../style/variables'

import Image1 from './Image1'
import Image2 from './Image2'

const Wrapper = styled.div`
  padding: 50px 0px 0px 0px;
`

const Flex = styled(FlexShort)`
  max-width: 1000px;
  margin: 20px auto;
`

const Heading = styled.h2`
  font-size: 50px;
  letter-spacing: 3px;
  font-family: ${fontPrimary};
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  padding: 30px 0;
  font-weight: bolder;
`

const Text = styled.p`
  text-align: justify;
  line-height: 2;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.7);
  font-family: ${fontSecondary};
  font-size: 16px;
  max-width: 1000px;
  margin: auto;
`

const para = `The word SEWA is a very common pure ancient Indian name meaning "Service" from Heart. Our Cityscape Calgary physiotherapy, Chiropractor & Massage Clinic is provide the royal and best treatment to our clients with best conservative treatment available to us. We will try our best to help our client to enjoy their life with pain free. Our services includes Physiotherapy, Massage, Chiropractor,Acupuncture,Dietitian, pelvicfloor rehabilitation etc. It is located in CityScape, Northeast, Calgary and focus mainly on NE Calgary, Cityscape, Skyview, Redstone, Savanna, Saddleridge, Martindale and Taradale community along with other communities of Calgary. At SEWA CityScape Physiotherapy and Massage Clinic, we are focused on promoting and preserving the health and well being of our patients.We will be treating patients with Motor vehicle accidents, Work related injuries through WCB, Sports injuries, Regular private clients, Clients with EHC, Neurological, vestibular clients etc. `

const TargetDiv = styled.div`
  height: 85px;
  width: 100%;
`

export default class About extends Component {
  render() {
    return (
      <Wrapper>
        <Container>
          <Heading>About Us</Heading>
          <Text>{para}</Text>
          <Flex justify="space-between">
            <Image1 />
            <Image2 />
          </Flex>
        </Container>
        <TargetDiv id="departments" />
      </Wrapper>
    )
  }
}
