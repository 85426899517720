import React, { Component } from 'react'
import DepartmentsData from './departmentsData'
import styled from 'styled-components'
import ScrollTo from 'react-scroll-into-view'

//  UI
import { Container } from '../../style/global'
import { fontPrimary, colorPrimary, colorBlack } from '../../style/variables.js'
import media from '../../style/media'

const Flex = styled.div`
  display: flex;
  background: #fff;
  max-width: 1000px;
  margin: 0px auto;
  border-radius: 4px;
  padding: 10px 10px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.21);
  justify-content: space-evenly;
  ${media.phone`
    flex-wrap: wrap;
  `};
`

const Span = styled.span`
  padding: 10px 10px;
  font-size: 20px;
  font-family: ${fontPrimary};
  background: ${({ highlight }) => (highlight ? colorPrimary : '#fff')};
  color: ${({ highlight }) => (highlight ? '#fff' : colorPrimary)};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  ${({ highlight }) => (highlight ? 'transform: scale(1.1);' : '')};
  &:hover {
    color: #fff;
    background: ${colorPrimary};
    transform: scale(1.1);
    cursor: pointer;
  }
`

const Wrapper = styled.div`
  background: ${colorPrimary};
  padding: 50px 0px 0 0;
`

const Heading = styled.h2`
  font-size: 50px;
  letter-spacing: 3px;
  font-family: ${fontPrimary};
  color: #fff;
  text-align: center;
  padding: 30px 0;
  font-weight: bolder;
  ${media.phone`
    font-size: 40px;
    margin: 0;
  `};
`

const Card = styled.div`
  border-radius: 5px;
  background: white;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.21);
  padding: 50px 20px;
  max-width: 1000px;
  margin: 20px auto;
  > a {
    color: blue;
    cursor: pointer;
    text-align: center;
    display: block;
  }
  > h2 {
    font-family: ${fontPrimary};
    color: ${colorBlack};
    font-size: 60px;
    margin: 0 0 30px 0;
    letter-spacing: 2px;
    text-align: center;
  }
  > div {
    display: flex;
    padding: 0px 30px;
    > span {
      flex: 1;
      > p {
        font-family: ${fontPrimary};
        color: rgba(0, 0, 0, 0.7);
        font-size: 20px;
        margin: 0 0 10px 0;
        line-height: 1.5;
        padding: 0 30px 0 0;
        /* text-align: center; */
      }
    }
    > div {
      flex: 1;
    }
  }
  > span {
    display: block;
    padding: 20px 30px;
    > h4 {
      font-size: 23px;
      font-family: ${fontPrimary};
    }
    > ul {
      > li {
        font-family: ${fontPrimary};
        font-size: 17px;
      }
    }
  }
  ${media.phone`
  padding: 30px 10px;
    > h2 {
      font-size: 30px;
    }
    > div {
      flex-direction: column;
      padding: 0 10px;
      > p {
        text-align: center;
        padding: 0;
      }
      > div {
        order: -1;
      }
    }
  `};
`

const A = styled.a`
  color: blue;
  cursor: pointer;
`

const TargetDiv = styled.div`
  height: 85px;
  width: 100%;
`
export default class Departments extends Component {
  state = {
    data: DepartmentsData,
    selected: DepartmentsData[0],
    readMore: false,
  }

  select = ({ name }) => {
    this.setState(({ data }) => {
      const data2 = data.find(({ name: depName }) => depName === name)
      return { selected: data2, readMore: false }
    })
  }

  toggleReadMore = e => {
    e.preventDefault()
    this.setState(({ readMore }) => ({ readMore: !readMore }))
  }

  render() {
    const {
      readMore,
      selected: { Image, name, description, points },
    } = this.state
    return (
      <Wrapper>
        <Container>
          <Heading>Departments</Heading>
          <Flex>
            {this.state.data.map(({ name }) => (
              <Span
                onClick={() => this.select({ name })}
                highlight={name === this.state.selected.name}
                key={name}
              >
                {name}
              </Span>
            ))}
          </Flex>
          <Card>
            <h2>{name}</h2>
            <div>
              <span>
                {description
                  .filter((el, i) => {
                    if (i === 0) {
                      return true
                    }
                    if (readMore) {
                      return true
                    }
                    return false
                  })
                  .map(desc => (
                    <p key={desc}>
                      {desc}
                      {!readMore && (
                        <A onClick={this.toggleReadMore}>
                          <em>...read more</em>
                        </A>
                      )}
                    </p>
                  ))}
              </span>
              {Image && <Image />}
            </div>
            {readMore &&
              points &&
              points.map(({ title, points }) => (
                <span key={title}>
                  <h4>{title}</h4>
                  <ul>
                    {points.map(point => (
                      <li key={point}>{point}</li>
                    ))}
                  </ul>
                </span>
              ))}
            {readMore && (
              <ScrollTo alignToTop selector="#departments">
                <A onClick={this.toggleReadMore}>
                  <em>read less</em>
                </A>
              </ScrollTo>
            )}
          </Card>
          <TargetDiv id="team" />
        </Container>
      </Wrapper>
    )
  }
}
