import Img from 'gatsby-image'
import styled from 'styled-components'

import media from './../../style/media'

export default styled(Img)`
  width: 100%;
  height: auto;
  border-radius: 3px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.21);
  margin: 10px 7px;
  transition: all 0.2s ease-out;
  &:hover {
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.3);
    transform: translateY(-3px);
    cursor: pointer;
  }
  ${media.phone`
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
  `};
`
