import React, { Component } from 'react'
import styled from 'styled-components'
import { Public } from 'styled-icons/material/Public.cjs'
import { PhoneCall } from 'styled-icons/feather/PhoneCall.cjs'
import { Mail } from 'styled-icons/feather/Mail.cjs'

// UI
import { Container } from './../../style/global'
import { fontPrimary, colorPrimary } from '../../style/variables'
import media from '../../style/media'

const styleIcon = Icon => styled(Icon)`
  margin-right: 10px;
  height: 20px;
`

const PublicIcon = styleIcon(Public)
const MailIcon = styleIcon(Mail)
const PhoneCallIcon = styleIcon(PhoneCall)

const Background = styled.div`
  background: rgba(356, 256, 256, 0.1);
  margin: 30px 0 0 0;
  padding: 20px 0px;
`

const Text2 = styled.p`
  font-size: 15px;
  padding: 5px 0 0 0;
  color: rgba(256, 256, 256, 0.3);
  margin: 0;
`

const Text = styled.div`
  text-align: center;
  color: white;
  font-family: ${fontPrimary};
  font-size: 20px;
  margin: 0;
  letter-spacing: 2px;
  position: relative;
  > a {
    text-transform: uppercase;
    font-weight: 900;
    color: ${colorPrimary};
    text-decoration: underline;
    > div {
      position: absolute;
      background: aliceblue;
      padding: 30px 50px;
      bottom: 55px;
      left: calc(50% - 311.5px);
      overflow: hidden;
      transform: translateY(300px);
      opacity: 0;
      transition: all 0.2s ease-out;
      box-shadow: 7px -7px 0px ${colorPrimary}, -7px 7px 0px ${colorPrimary};
      > h1 {
        font-size: 25px;
        color: black;
        margin: 0 0 10px 0;
        letter-spacing: 5px;
      }
      > h2 {
        font-size: 15px;
        margin: 0 0 5px 0;
        color: rgb(0, 0, 0, 0.7);
      }
      > h3 {
        font-size: 10px;
        color: rgb(0, 0, 0, 0.3);
      }
      > a {
        color: rgb(0, 0, 0, 0.7);
        text-decoration: underline;
        font-size: 15px;
        display: block;
        text-align: left;
        text-transform: lowercase;
        padding: 5px 0px;
      }
      > p {
        color: rgb(0, 0, 0, 0.3);
        font-size: 10px;
        text-align: center;
        text-transform: uppercase;
        margin: 0;
      }
      ${media.phone`
        width: 100%;
        left: 0;
        margin: 0 auto;
        padding: 20px 30px;
      `};
    }
    ${media.phone`
        display: block;
      `};
    &:hover {
      > div {
        opacity: 1;
        transform: translateY(-50px);
      }
    }
  }
`

export default class Credits extends Component {
  render() {
    return (
      <Background>
        <Container>
          <Text>
            Made by the folks at
            <a onClick={e => e.preventDefault()} href="#">
              🔥 Snehwill 🔥
              <div>
                <h1>SNEHWILL</h1>
                <h2>
                  <em>We make your work easy by taking it in our hands.</em>
                </h2>
                <h3>Web Development | Android Development | UI/UX</h3>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="http://www.snehwill.com"
                >
                  <PublicIcon />
                  snehwill.com
                </a>
                <a href="tel:+919599251045">
                  <PhoneCallIcon />
                  +91-95992-51045
                </a>
                <a href="mailto:snehwill@gmail.com">
                  {' '}
                  <MailIcon />
                  snehwill@gmail.com
                </a>
                <p>A Certified ISO Company</p>
              </div>
            </a>
            <Text2>
              &copy; {new Date().getFullYear()} | All rights Reserved
            </Text2>
          </Text>
        </Container>
      </Background>
    )
  }
}
