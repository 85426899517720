import React, { Component } from 'react'
import styled from 'styled-components'
import { MapPin } from 'styled-icons/feather/MapPin.cjs'
import { PhoneCall } from 'styled-icons/feather/PhoneCall.cjs'
import { Mail } from 'styled-icons/feather/Mail.cjs'
import { Facebook } from 'styled-icons/feather/Facebook.cjs'
// UI
import { Container, Flex } from './../../style/global'
import { fontPrimary, colorPrimary } from '../../style/variables'
import MapIframe from './MapIframe'
import Credits from './Credits'

const Background = styled.div`
  background: #000;
  padding: 50px 0px 0px 0px;
  overflow: hidden;
`

const Pin = styled(MapPin)`
  margin-right: 20px;
  height: 30px;
`

const Phone = styled(PhoneCall)`
  margin-right: 20px;
  height: 30px;
`

const Email = styled(Mail)`
  margin-right: 20px;
  height: 30px;
`
const FBIcon = styled(Facebook)`
  margin-right: 20px;
  height: 30px;
`
const Heading = styled.h2`
  font-size: 50px;
  letter-spacing: 3px;
  font-family: ${fontPrimary};
  color: #fff;
  text-align: center;
  padding: 30px 0;
  font-weight: bolder;
`

const ContactCard = styled.div`
  > h4 {
    font-size: 30px;
    letter-spacing: 3px;
    font-family: ${fontPrimary};
    color: #fff;
    padding: 20px 0;
    font-weight: bolder;
  }
  > a {
    text-decoration: none;
    color: white;
    color: #fff;
    font-family: ${fontPrimary};
    font-size: 18px;
    display: block;
    padding: 10px 0px;
    > span {
      color: ${colorPrimary};
      font-family: ${fontPrimary};
      font-size: 18px;
      font-weight: 700;
      margin-left: 50px;
      display: block;
    }
  }
`

export default class ContactUs extends Component {
  render() {
    return (
      <Background>
        <Container>
          <Heading>Contact Us</Heading>
          <Flex justify="space-between">
            <ContactCard>
              <h4>Get In Touch</h4>
              <a href="mailto:cityscapephysiotherapy@gmail.com">
                <Email />
                Mail us at <span>cityscapephysiotherapy@gmail.com</span>
              </a>
              <a href="tel:5877074466">
                <Phone />
                Call us at <span>587-707-4466</span>
              </a>
              <a href="https://goo.gl/maps/rKbKhrFD3PC2">
                <Pin /> Visit Us at
                <br />
                <span>
                  #108, 3880 104 Avenue Northeast Calgary, Alberta-T3N0T1 Canada
                </span>
              </a>
              <a href="https://www.facebook.com/SEWACITYSCAPE/">
                <FBIcon /> Like us on
                <br />
                <span>Facebook</span>
              </a>
            </ContactCard>
            <MapIframe />
          </Flex>
        </Container>
        <Credits />
      </Background>
    )
  }
}
