import React, { Component } from 'react'
import styled from 'styled-components'
// UI
import media from '../../style/media'

const Iframe = styled.iframe`
  border: 0;
  height: 450px;
  width: 500px;
  ${media.phone`
    width: 100%;
  `};
`

export default class MapIframe extends Component {
  render() {
    return (
      <Iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10011.577994293651!2d-113.9782021!3d51.1472966!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa94bce4a27e07c8!2sCalgary+Northeast+SEWA+Physiotherapy+Chiropractor+and+Massage+Clinic!5e0!3m2!1sen!2sin!4v1541526384855"
        allowFullScreenframeBorder={0}
      />
    )
  }
}
